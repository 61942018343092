import React, { useMemo } from "react"
import Layout from "../components/layout"
import Spacer from "../components/spacer"
import Journal from "../components/journal"
import Capabilities from "../components/capabilities"
import FeatureProject from "../components/featureProject"
import MoreProjects from "../components/projectsFeatured"
import FeatureImage from "../components/featureImage"
import Title from "../components/titleAnimate"
import Quote from "../components/quote"
import About from "../components/about"
import Cta from "../components/cta"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const IndexPage = ({ data }) => {
  var home = data.home
  const projects = useMemo(() => {
    return data.projects.nodes.filter(({ id }) => id !== home.featureProject.id)
  }, [home.featureProject.id, data.projects])

  return (
    <Layout preloader={true}>
      <Spacer />
      
      <Title first={home.titleOne} second={home.titleTwo} symbol="true" delay={3} />

      <FeatureImage className='m-ratio-1-2' image={home.featureImage} />
      
      <About title={home.aboutTitle} content={home.aboutContent} link={home.aboutLink}/>

      <FeatureProject project={home.featureProject} />
      
      <MoreProjects projects={projects} bottomBar="true" title="More Projects"/>
      
      <Quote author={home.quoteAuthor} content={home.quote} />
      
      <Capabilities capabilities={home.capability} content={home.capabilityIntroduction} />
      
    </Layout>
  )
}

export default IndexPage

export const Head = () => <Seo title="Home" />

export const query = graphql`
  query HomePageQuery {
    home: datoCmsHomepage {
      titleOne
      titleTwo
      featureImage {
        gatsbyImageData
      }
      aboutTitle
      aboutContent
      aboutLink
      featureProject {
        id
        slug
        title
        builder
        category
        completed
        featureImage {
          gatsbyImageData
        }
      }
      quote
      quoteAuthor
      capabilityIntroduction
      capability {
        title
        content
      }
      ctaTitle
      ctaLink
      ctaImage {
        gatsbyImageData
      }
    }
    blog: allDatoCmsBlogPost(limit: 3) {
      nodes {
        title
        slug
        category
        featureImage {
          gatsbyImageData
        }
      }
    }
    projects: allDatoCmsProject {
      nodes {
        id
        slug
        title
        category
        completed
        featureImage {
          gatsbyImageData
        }
      }
    }
  }
`
